<template>
  <el-main>
    <el-button
      type="primary"
      size="small"
      @click="$router.push('/extension/shareholderDividends/addShareholderDividends')"
      >添加区域股东</el-button
    >
    <el-form class="el-form-search" label-width="100px">
      <el-form-item label="代理名称：">
        <el-input
          size="small"
          v-model="searchItem.orderNum"
          placeholder="请输入门店名称/联系人/电话"
        ></el-input>
      </el-form-item>
      <el-form-item label="注册日期：">
        <el-date-picker
          v-model="searchItem.time"
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          size="small"
          @change="timeChange"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item label="代理状态：">
        <el-select v-model="searchItem.proxyStatus" size="small" placeholder="请选择">
          <el-option
            v-for="item in proxyStatusList"
            :key="item.status"
            :label="item.name"
            :value="item.status"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="所属地区：">
        <el-select v-model="searchItem.cityId" size="small" placeholder="请选择">
          <el-option
            v-for="item in cityList"
            :key="item.city_id"
            :label="item.city_name"
            :value="item.city_id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label=" " label-width="30px">
        <el-button type="primary" size="small" @click="getShopList('search')">搜索</el-button>
        <el-button size="small">导出</el-button>
        <el-button type="text" size="small" @click="cancelSearch">清空搜索条件</el-button>
      </el-form-item>
    </el-form>
    <el-table :data="dataList" :header-cell-style="{ 'background-color': '#F8F9FA' }">
      <template slot="empty">
        <No />
      </template>
      <el-table-column prop="name" label="代理名称" align="center"> </el-table-column>
      <el-table-column label="代理区域" align="center">
        <template v-slot="{ row }"
          >{{ row.province_name || '' }}{{ row.city_name || '' }}{{ row.area_name || '' }}</template
        >
      </el-table-column>
      <el-table-column label="用户昵称" align="center">
        <template v-slot="{ row }"
          ><UserInfo :name="row.member.nickname" :avatar="row.member.avatar"></UserInfo
        ></template>
      </el-table-column>
      <el-table-column prop="contacts_name" label="联系人" align="center"> </el-table-column>
      <el-table-column prop="contacts_phone" label="手机号码" align="center"> </el-table-column>
      <el-table-column label="注册时间" align="center" width="160">
        <template v-slot="{ row }">{{ getDateformat(row.create_time) }}</template>
      </el-table-column>
      <el-table-column prop="price" label="代理价格" align="center"> </el-table-column
      ><el-table-column label="代理状态" align="center">
        <template v-slot="{ row }">
          <el-switch
            @change="changeStatus(row)"
            v-model="row.status"
            :active-value="1"
            :inactive-value="0"
          >
          </el-switch>
        </template>
      </el-table-column>
      <el-table-column prop="shop_num" label="店铺数量" align="center"> </el-table-column>
      <el-table-column prop="member_num" label="会员数量" align="center"> </el-table-column>
      <el-table-column label="操作" align="center">
        <template v-slot="{ row }">
          <el-button
            type="text"
            size="small"
            @click="
              $router.push(`/extension/shareholderDividends/addShareholderDividends?id=${row.id}`)
            "
            >编辑</el-button
          >
          <el-button type="text" size="small" @click="deleteData(row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <Paging :total="total" :page="page" :pageNum="rows" @updatePageNum="updateData"></Paging>
  </el-main>
</template>

<script>
import Paging from '@/components/paging'
import UserInfo from '@/components/userInfo'
import { getDateformat } from '@/util/getDate.js'
export default {
  components: {
    Paging,
    UserInfo
  },
  data() {
    return {
      getDateformat,
      searchItem: {
        proxyName: '',
        time: [],
        proxyStatus: -1,
        cityId: 0
      },
      cityList: [{ city_name: '全部', city_id: 0 }],
      proxyStatusList: [
        { status: -1, name: '全部' },
        { status: 0, name: '停用' },
        { status: 1, name: '启用' }
      ],
      dataList: [],
      page: 1,
      rows: 10,
      total: 0
    }
  },
  methods: {
    updateData(val, status) {
      if (status == 0) {
        this.rows = val
      } else {
        this.page = val
      }
      this.getShopList()
    },
    cancelSearch() {
      this.page = 1
      this.searchItem = {
        proxyName: '',
        time: [],
        proxyStatus: -1,
        cityId: 0
      }
      this.getShopList()
    },
    // 获取页面列表信息
    getShopList(style) {
      if (style) {
        this.page = 1
      }
      let obj = {
        page: this.page,
        rows: this.rows
      }
      let time = this.searchItem.time
      if (time.length) {
        // 如果需要按时间查询
        obj.create_time = [Math.ceil(time[0].getTime() / 1000), Math.ceil(time[1].getTime() / 1000)]
        if (obj.create_time[0] == obj.create_time[1]) {
          obj.create_time[1] += 60 * 60 * 24 - 1
        }
      }
      if (this.searchItem.proxyName) {
        obj.name = this.searchItem.proxyName
      }
      if (this.searchItem.cityId) {
        obj.city_id = this.searchItem.cityId
      }
      if (this.searchItem.proxyStatus != -1) {
        obj.status = this.searchItem.proxyStatus
      }
      this.$axios.post(this.$api.push.regionalShareholders.list, obj).then((res) => {
        if (res.code === 0) {
          this.dataList = res.result.list
          this.total = res.result.total_number
        }
      })
    },
    getCity() {
      this.$axios.post(this.$api.samecity.selectCityList).then((res) => {
        if (res.code == 0) {
          this.cityList = this.cityList.concat(res.result.list)
        }
      })
    },
    changeStatus(row) {
      this.$axios
        .post(this.$api.push.regionalShareholders.setDefault, {
          id: row.id,
          status: row.status
        })
        .then((res) => {
          if (res.code == 0) {
            let str = !row.status ? '停用成功' : '启用成功'
            this.$message.success(str)
          } else {
            row.status = row.status ? 0 : 1
            this.$message.error('更改失败')
          }
        })
    },
    deleteData(row) {
      this.$axios
        .post(this.$api.push.regionalShareholders.del, {
          id: row.id
        })
        .then((res) => {
          if (res.code == 0) {
            this.$message.success('删除成功')
            this.getShopList()
          } else {
            this.$message.error(res.msg)
          }
        })
    }
  },
  created() {
    this.getCity()
    this.getShopList()
  }
}
</script>

<style lang="less" scoped>
.el-main {
  background-color: #fff;
  & > .el-button {
    margin-bottom: 10px;
  }
}
</style>
